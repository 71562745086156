export function startCast(fnInitGame, fnCallNum, fnRepeatNum, fnEndGame) {
    var gcastScript = document.createElement('script');
    gcastScript.src = '//www.gstatic.com/cast/sdk/libs/caf_receiver/v3/cast_receiver_framework.js';

    gcastScript.onload = function() {

        const context = cast.framework.CastReceiverContext.getInstance();

        const CUSTOM_CHANNEL = 'urn:x-cast:com.ezbingocaller.cast';
        context.addCustomMessageListener(CUSTOM_CHANNEL, function(bingoEvent) {
            var eventData = bingoEvent.data;
            console.log('received bingo event - ' + eventData)
            switch (eventData.type) {
                case 'initGame':
                    fnInitGame(eventData.calledNums)
                    playSampleAudio(context)
                    break;
                case 'callNum':
                    fnCallNum(eventData.calledNum)
                    break;
                case 'repeatNum':
                    fnRepeatNum(eventData.calledNum)
                    break;
                case 'resetUI':
                    fnEndGame()
                    break;
                default:
                    break;
            }
        });

        context.start();
    };

    gcastScript.onerror = function() {
        console.log("Error loading google cast library");
    }

    document.head.appendChild(gcastScript);

}

function playSampleAudio(context) {
    var media = new cast.framework.messages.MediaInformation()
    context.contentUrl = './audio/num_75.mp3'

    var loadInfo = new cast.framework.messages.LoadRequestData()
    loadInfo.autoplay = true
    loadInfo.media = media

//   var qi = new cast.framework.messages.QueueItem()
//    qi.media = media

//    context.getPlayerManager().getQueueManager().insertItems(qi)
    context.getPlayerManager().load(loadInfo)

}