import './bingo.css';
import * as bingoCast from './cast';
var ArrState = /** @class */ (function () {
    function ArrState(size, restore) {
        if (restore === void 0) { restore = true; }
        if (restore) {
            this.restore();
            if (this.state) {
                return;
            }
        }
        this.size = size;
        this.state = [];
        for (var index = 0; index < size; index++) {
            this.state.push(false);
        }
        this.save();
    }
    ArrState.prototype.get = function (index) {
        return this.state[index];
    };
    ArrState.prototype.set = function (index) {
        this.state[index] = true;
        this.save();
    };
    ArrState.prototype.reset = function () {
        for (var index = 0; index < this.size; index++) {
            this.state[index] = false;
        }
    };
    ArrState.prototype.getSize = function () {
        return this.size;
    };
    ArrState.prototype.numbersPicked = function () {
        var numbersPicked = 0;
        for (var i = 0; i < this.state.length; i++) {
            if (this.state[i]) {
                numbersPicked++;
            }
        }
        return numbersPicked;
    };
    ArrState.prototype.calledNums = function () {
        var numbers = [];
        for (var i = 0; i < this.state.length; i++) {
            if (this.state[i]) {
                numbers.push(i + 1);
            }
        }
        return numbers;
    };
    ArrState.prototype.save = function () {
        var stateStr = '';
        for (var i = 0; i < this.state.length; i++) {
            stateStr += this.state[i] ? '1' : '0';
        }
        localStorage.setItem('gameState', stateStr);
    };
    ArrState.prototype.restore = function () {
        var stateStr = localStorage.getItem('gameState');
        if (!stateStr) {
            return;
        }
        this.size = stateStr.length;
        this.state = [];
        for (var i = 0; i < stateStr.length; i++) {
            this.state.push(stateStr[i] == '1');
        }
    };
    return ArrState;
}());
var BufferLoader = /** @class */ (function () {
    function BufferLoader(context, listener, urlList) {
        this.context = context;
        this.listener = listener;
        this.urlList = urlList;
        this.bufferList = new Array();
    }
    BufferLoader.prototype.loadBuffer = function (url, index) {
        var request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.responseType = 'arraybuffer';
        var loader = this;
        request.onload = function () {
            loader.context.decodeAudioData(request.response, function (buffer) {
                if (!buffer) {
                    console.log('Error decoding file data: ' + url);
                    return;
                }
                loader.bufferList[index] = buffer;
                if (loader.bufferList.length == loader.urlList.length) {
                    loader.listener.buffersLoaded(loader.bufferList);
                }
            }, function (error) {
                console.error('decodeAudioData error', error);
            });
        };
        request.onerror = function () {
            console.log('BufferLoader: XHR error');
        };
        request.send();
    };
    BufferLoader.prototype.load = function () {
        for (var index = 0; index < this.urlList.length; index++) {
            this.loadBuffer(this.urlList[index], index);
        }
    };
    return BufferLoader;
}());
var AudioPlayer = /** @class */ (function () {
    function AudioPlayer(urls) {
        this.urlList = urls;
        this.sources = new Array();
        this.init();
    }
    AudioPlayer.prototype.init = function () {
        this.context = new AudioContext();
        var bufferLoader = new BufferLoader(this.context, this, this.urlList);
        bufferLoader.load();
    };
    AudioPlayer.prototype.buffersLoaded = function (bufferList) {
        for (var i = 0; i < bufferList.length; i++) {
            var bufferSource = this.context.createBufferSource();
            bufferSource.buffer = bufferList[i];
            bufferSource.connect(this.context.destination);
            this.sources.push(bufferSource);
        }
        this.soundBuffer = bufferList;
    };
    AudioPlayer.prototype.play = function (index) {
        this.sources[index - 1].start(0);
        this.sources[index - 1] = this.context.createBufferSource();
        this.sources[index - 1].buffer = this.soundBuffer[index];
        this.sources[index - 1].connect(this.context.destination);
    };
    return AudioPlayer;
}());
var BingoCaller = /** @class */ (function () {
    function BingoCaller(size) {
        this.state = new ArrState(size);
        this.size = this.state.getSize();
        this.numbersPicked = this.state.numbersPicked();
    }
    BingoCaller.prototype.next = function () {
        var nextNum = Math.floor(Math.random() * (this.size - this.numbersPicked));
        var emptyCount = 0;
        for (var index = 0; index < this.size; index++) {
            if (!this.state.get(index)) {
                if (emptyCount == nextNum) {
                    this.state.set(index);
                    this.numbersPicked++;
                    return index + 1;
                }
                emptyCount++;
            }
        }
        return -1;
    };
    BingoCaller.prototype.reset = function () {
        this.state = new ArrState(this.size, false);
        this.numbersPicked = 0;
    };
    BingoCaller.prototype.isGameOver = function () {
        return this.numbersPicked == this.size;
    };
    BingoCaller.prototype.calledNums = function () {
        return this.state.calledNums();
    };
    return BingoCaller;
}());
function startGame() {
    return new BingoCaller(75);
}
function loadAudioBuffer() {
    var urls = new Array();
    for (var i = 1; i <= 75; i++) {
        urls.push('./audio/num_' + i + '.mp3');
    }
    return new AudioPlayer(urls);
}
function loadAudio() {
    var audioMap = new Map();
    for (var index = 1; index <= 75; index++) {
        var ad = new Audio('./audio/num_' + index + '.mp3');
        ad.id = index.toString();
        // ad.hidden = true
        document.body.appendChild(ad);
        //audioMap.set('' + index, new Audio('./audio/num_' + index + '.mp3'))
    }
    return audioMap;
}
function addBingoElement(eleId) {
    var bingoEle = document.createElement('div');
    bingoEle.id = eleId;
    document.body.appendChild(bingoEle);
    return bingoEle;
}
function createGameUI(parent, castUI) {
    parent === null || parent === void 0 ? void 0 : parent.append(createPickedNumsUI());
    var detailsPanel = createDiv("detailsPanel", "detailsPanel");
    parent === null || parent === void 0 ? void 0 : parent.append(detailsPanel);
    if (!castUI) {
        detailsPanel.append(createButtonsPanel());
    }
    var calledNums = createDiv("calledNums", "calledNums");
    detailsPanel.append(calledNums);
}
function createEventHandlers(game, audio) {
    var nextBtn = document.getElementById("nextButton");
    nextBtn.onclick = function () {
        callNextNum(game, $("#calledNums")[0], audio);
    };
    nextBtn.focus();
    var resetBtn = document.getElementById("resetButton");
    resetBtn.onclick = function () {
        resetGame(game);
    };
    var nums = game.calledNums();
    for (var i = 0; i < nums.length; i++) {
        updatePickedNum(nums[i]);
    }
}
function initCast(audio) {
    bingoCast.startCast(function (nums) {
        for (var i = 0; i < nums.length; i++) {
            updatePickedNum(nums[i]);
        }
    }, function (nextNum) {
        updateCalledNums(nextNum, $("#calledNums")[0]);
        //playAudio(nextNum, audio)
        audio.play(nextNum);
    }, function (num) {
        //playAudio(num, audio)
        audio.play(num);
    }, function () {
        resetGameUI();
    });
}
function initBingo() {
    var castApp = document.location.href.endsWith("/chromecast");
    var parent = addBingoElement("_bingoApp");
    // let audio = loadAudio()
    var audioPlayer = loadAudioBuffer();
    createGameUI(parent, castApp);
    if (castApp) {
        initCast(audioPlayer);
    }
    else {
        createEventHandlers(startGame(), audioPlayer);
    }
}
function callNextNum(game, calledNums, audio) {
    var nextNum = game.next();
    updateCalledNums(nextNum, calledNums);
    //playAudio(nextNum, audio)
    audio.play(nextNum);
}
function resetGame(game) {
    game.reset();
    resetGameUI();
}
function createDiv(className, id) {
    var divEle = document.createElement("div");
    divEle.className = className;
    divEle.id = id;
    return divEle;
}
function createPickedNumsUI() {
    var pickedNums = createDiv("pickedNums", "pickedNums");
    pickedNums.appendChild(createPickedNumsRow('b', Array.from(Array(15).keys()).map(function (x) { return x + 1; })));
    pickedNums.appendChild(createPickedNumsRow('i', Array.from(Array(15).keys()).map(function (x) { return x + 16; })));
    pickedNums.appendChild(createPickedNumsRow('n', Array.from(Array(15).keys()).map(function (x) { return x + 31; })));
    pickedNums.appendChild(createPickedNumsRow('g', Array.from(Array(15).keys()).map(function (x) { return x + 46; })));
    pickedNums.appendChild(createPickedNumsRow('o', Array.from(Array(15).keys()).map(function (x) { return x + 61; })));
    return pickedNums;
}
function createPickedNumsRow(letter, nums) {
    var pickedNumsRow = createDiv("pickedNumRow", "pickedNumRow_" + letter);
    var spNumEle = document.createElement("span");
    spNumEle.className = "pickedNumLetter";
    spNumEle.id = "pickedNumLetter_" + letter;
    spNumEle.textContent = letter;
    pickedNumsRow.appendChild(spNumEle);
    for (var index = 0; index < nums.length; index++) {
        var spNumEle_1 = document.createElement("span");
        spNumEle_1.className = "pickedNum";
        spNumEle_1.id = "pickedNum_" + nums[index];
        spNumEle_1.textContent = nums[index].toString();
        pickedNumsRow.appendChild(spNumEle_1);
    }
    return pickedNumsRow;
}
function createButtonsPanel() {
    var buttonsPanel = document.createElement("div");
    buttonsPanel.className = "buttonsPanel";
    buttonsPanel.append(createNextButton());
    buttonsPanel.append(createResetButton());
    return buttonsPanel;
}
function createNextButton() {
    var nextBtn = document.createElement("input");
    nextBtn.id = "nextButton";
    nextBtn.className = "nextButton";
    nextBtn.type = "button";
    nextBtn.value = "Next";
    return nextBtn;
}
function createResetButton() {
    var resetBtn = document.createElement("input");
    resetBtn.id = "resetButton";
    resetBtn.className = "resetButton";
    resetBtn.type = "button";
    resetBtn.value = "Reset";
    return resetBtn;
}
function resetGameUI() {
    var calledNums = document.getElementById('calledNums');
    while (calledNums.hasChildNodes()) {
        calledNums.removeChild(calledNums.lastChild);
    }
    for (var index = 1; index <= 75; index++) {
        var pickedEle = document.getElementById("pickedNum_" + index);
        pickedEle.classList.remove('highlighted');
    }
}
function updateCalledNums(nextNum, calledNums) {
    var numEle = document.createElement("span");
    numEle.classList.add("newNum");
    numEle.innerText = "" + nextNum;
    calledNums.childNodes.forEach(function (calledNum) {
        if (calledNum instanceof HTMLSpanElement && calledNum.classList) {
            calledNum.classList.remove("newNum");
        }
    });
    if (calledNums.childNodes.length == 10) {
        calledNums.removeChild(calledNums.childNodes[9]);
    }
    calledNums === null || calledNums === void 0 ? void 0 : calledNums.insertBefore(numEle, calledNums.childNodes[0]);
    updatePickedNum(nextNum);
}
function updatePickedNum(nextNum) {
    var pickedEle = document.getElementById("pickedNum_" + nextNum);
    pickedEle.classList.add('highlighted');
}
function playAudio(nextNum, audio) {
    var _a;
    (_a = document.getElementById(nextNum.toString())) === null || _a === void 0 ? void 0 : _a.play();
    // audio.get('' + nextNum)?.play()
}
$(function () {
    initBingo();
});
